<template>
  <div class="layout">
    <div class="flex xs4 filter-field">
      <v-select
        v-model="filterField"
        :items="availableFields"
        :label="$t('interface.field')"
        item-value="code"
        item-text="text"
        hide-details
        @change="resetEntitySearch"
      />
    </div>
    <div class="flex xs8 filter-control">
      <v-select
        v-if="isEntityFiltering"
        :items="entitySearch.items"
        item-value="id"
        item-text="name"
        :placeholder="$t('interface.search')"
      >
        <template v-slot:prepend-item>
          <v-text-field
            v-model="entitySearch.searchValue"
            :placeholder="$t('interface.type_for_searching')"
            class="ma-2"
            hide-details
            dense
          />
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:append-item>
          <div v-if="entitySearch.more.has" v-intersect="onWantMore" />
          <div v-if="entitySearch.more.isLoading" class="text-center">
            <v-progress-circular indeterminate color="primary" />
          </div>
        </template>
      </v-select>
      <v-text-field
        v-else-if="isTextFiltering"
        v-model="textSearch.searchValue"
      />
    </div>
  </div>
</template>
<script>
import Helpers from "../../utils/helpers";

export default {
  name: "FilterField",
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: "id",
    },
    itemLabel: {
      type: String,
      default: "name",
    },
  },
  data() {
    return {
      filterField: null,
      entitySearch: {
        searchValue: null,
        items: [],
        more: {
          isLoading: false,
          has: true,
        },
        page: 1,
        size: 10,
        debouncedGetEntities: null,
      },
      textSearch: {
        searchValue: null,
      },
    };
  },
  computed: {
    currentField() {
      return this.fields.find((f) => this.filterField === (f.value || f.field));
    },
    isEntityFiltering() {
      if (!this.currentField) {
        return false;
      }
      return this.currentField.type === "entity";
    },
    isTextFiltering() {
      if (!this.currentField) {
        return false;
      }
      return this.currentField.type === "text";
    },
    isItemsFiltering() {
      if (!this.currentField) {
        return false;
      }
      return this.currentField.type === "text" && this.currentField.items;
    },
    availableItems() {
      return [];
    },
    availableFields() {
      return this.fields
        .filter((i) => i.filtering)
        .map(({ field, value, translation }) => {
          return {
            code: value || field,
            text: this.$t("interface." + (translation || field)),
          };
        });
    },
  },
  beforeMount() {
    this.filterField = this.availableFields[0]?.code;
    for (let i = 0; i < 100; i++) {
      this.entitySearch.items.push({ id: i, name: i });
    }
    this.entitySearch.debouncedGetEntities = Helpers.debounce(
      this.getMoreEntities,
      500
    );
  },
  watch: {
    filterField() {
      this.resetEntitySearch();
    },
    "entitySearch.searchValue": {
      handler() {
        this.resetEntitySearch();
        this.entitySearch.debouncedGetEntities();
      },
    },
  },
  methods: {
    resetEntitySearch() {
      this.entitySearch.items = [];
      this.entitySearch.more.has = true;
      this.entitySearch.page = 1;
    },
    async fetchEntities(page, size) {
      const filtering = { field: null, value: null };
      if (this.entitySearch.searchValue) {
        filtering.field = "name";
        filtering.value = this.entitySearch.searchValue;
      }

      const { items } = await this.$api.getUsers(
        {
          page,
          size,
        },
        {},
        filtering
      );
      return items;
    },
    onWantMore(entries) {
      if (entries[0].intersectionRatio >= 0.5) {
        this.entitySearch.debouncedGetEntities();
      }
    },
    async getMoreEntities() {
      if (!this.entitySearch.more.has) {
        return;
      }
      this.entitySearch.more.isLoading = true;
      const size = this.entitySearch.size;

      const items = await this.fetchEntities(++this.entitySearch.page, size);

      if (items.length) {
        this.entitySearch.items.push(...items);
      }

      if (items.length < size) {
        this.entitySearch.more.has = false;
      }

      this.entitySearch.more.isLoading = false;
    },
  },
};
</script>
