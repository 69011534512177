import Vue from "vue";
import VueRouter from "vue-router";
import UserRole from "../utils/user-roles";
/** layouts **/
import EmptyLayout from "../layoutes/empty.vue";
import SidebarLayout from "../layoutes/with-sidebar.vue";
import store from "../store";
/** views **/
import LoginPage from "../views/login/index.vue";

const UsersPage = () => import("../views/users");
const DevicesPage = () => import("../views/devices");
const DevicesWebPage = () => import("../views/devices/web");
const SessionsPage = () => import("../views/sessions");

const Page404 = () => import("../views/404-page.vue");

Vue.use(VueRouter);

export const defaultRoutesForUsers = {
  [UserRole.admin]: "users",
  [UserRole.manager]: "devices",
};

const routes = [
  {
    path: "/",
    name: "root-empty",
    component: EmptyLayout,
    meta: {
      availableFor: [],
    },
    children: [
      {
        name: "login",
        path: "/login",
        component: LoginPage,
      },
    ],
  },
  {
    path: "/devices/web",
    component: DevicesWebPage,
    name: "devices-web",
    meta: {
      availableFor: [UserRole.admin, UserRole.manager],
    },
  },
  {
    path: "/",
    name: "root-sidebar",
    component: SidebarLayout,
    meta: {
      availableFor: [],
    },
    children: [
      {
        path: "/users",
        component: UsersPage,
        name: "users",
        meta: {
          availableFor: [UserRole.admin],
        },
      },
      {
        path: "/devices",
        component: DevicesPage,
        name: "devices",
        meta: {
          availableFor: [UserRole.admin, UserRole.manager],
        },
      },
      {
        path: "/sessions",
        component: SessionsPage,
        name: "sessions",
        meta: {
          availableFor: [UserRole.admin],
        },
      },

      {
        path: "*",
        name: "404",
        component: Page404,
        meta: {
          availableFor: [UserRole.admin, UserRole.manager],
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { X: 0, y: 0 };
  },
});

/** auth guard **/
router.beforeEach((to, from, next) => {
  if (store.state.isUserAuthenticated) {
    if (to.name === "login") {
      next({
        name: defaultRoutesForUsers[store.state.userType],
      });
      return;
    } else {
      if (
        to.meta.availableFor &&
        !to.meta.availableFor.includes(store.state.userType)
      ) {
        next({
          name: defaultRoutesForUsers[store.state.userType],
        });
        return;
      }
    }
  } else {
    if (to.name !== "login") {
      next({ name: "login" });
      return;
    }
  }
  window.scrollTo(0, 0);
  next();
});

export default router;
