// import { UnknownServiceError } from "@/services/errors";

class Container {
  services = [];
  aliases = [];

  bind(alias, implementation) {
    this.services[alias] = implementation;
  }

  get(alias) {
    // if (!this.aliases[alias]) {
    //   throw new UnknownServiceError(alias);
    // }
    if (this.services[alias]) {
      return this.services[alias];
    }
  }
}

export default new Container();
