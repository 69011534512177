export default {
  dateFormat(date, format) {
    format = format || "date";
    let result;
    let dateString = "";
    let timeString = "";
    if (format.indexOf("date") >= 0) {
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let day = date.getDate();

      month = String(month).length === 1 ? "0" + month : month;
      day = String(day).length === 1 ? "0" + day : day;
      dateString = year + "-" + month + "-" + day;
      result = dateString;
    }
    if (format.indexOf("time") >= 0) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      hours = String(hours).length === 1 ? "0" + hours : hours;
      minutes = String(minutes).length === 1 ? "0" + minutes : minutes;
      timeString = hours + ":" + minutes;
      if (format.indexOf("sec") >= 0) {
        let seconds = date.getSeconds();
        timeString =
          timeString +
          ":" +
          (String(seconds).length === 1 ? "0" + seconds : seconds);
      }
      result = (result ? result + " " : "") + timeString;
    }
    return result;
  },
  /**
   * get tz offset in minutes without local user tz
   * @param tz
   * @returns {number}
   */
  getTimeZoneOffsetWOLocal(tz) {
    const [hours, minutes] = tz.split(":");
    return (
      (Number.parseInt(hours.replace("UTC", "")) || 0) * 60 +
      Number.parseInt(minutes) * (tz.indexOf("-") >= 0 ? -1 : 1)
    );
  },

  /**
   * get tz offset in minutes with local user tz
   * @param tz
   * @returns {number}
   */
  getTimeZoneOffset(tz) {
    let result = new Date().getTimezoneOffset();
    return result + this.getTimeZoneOffsetWOLocal(tz);
  },
  /**
   * convert timestamp to date with tz ot tz offset in minutes
   * @param timestamp
   * @param tz
   * @param tzOffset
   * @returns {any}
   */
  unixtimeToDate(timestamp, tz = null, tzOffset = 0) {
    if (tz) {
      tzOffset = this.getTimeZoneOffset(tz);
    }
    return timestamp ? new Date((timestamp + tzOffset * 60) * 1000) : null;
  },
  datetimeToUnix(datetime) {
    return datetime.getTime() / 1000;
  },
  getI18nKey(str) {
    return str.toLowerCase().replace(/[\s'"]/g, "_");
  },
  getErrorMessage(context, error) {
    let message = context.$t("errors.e_undefined");
    if (error.response && error.response.data.error !== undefined) {
      message = context.$t(
        "errors." +
          "e" +
          error.response.status +
          "_" +
          this.getI18nKey(error.response.data.error)
      );

      message = message || error.response.data.error;
    }
    return message;
  },
  objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }
    return target;
  },
  initInterface() {},
  isObject(obj) {
    return obj === Object(obj);
  },
  isString(x) {
    return Object.prototype.toString.call(x) === "[object String]";
  },
  isArray(x) {
    return Object.prototype.toString.call(x) === "[object Array]";
  },
  isNull(val) {
    return val === null;
  },
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  },
  normalizeInt(val) {
    return +val;
  },
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  cloneDeep(val) {
    return JSON.parse(JSON.stringify(val));
  },
  keys(obj) {
    return Object.keys(obj);
  },
  values(obj) {
    return Object.values(obj);
  },
  debounce(func, wait) {
    let timeout;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  },
  getParameterizedString(template, params) {
    if (!params) {
      return template;
    }
    let result = template;
    for (const [k, v] of Object.entries(params)) {
      result = result.replace(`{${k}}`, v);
    }
    return result;
  },
  normalizeApartments(apartments) {
    return apartments
      .map((i) => {
        const parts = i.split("-");
        if (parts[1] !== undefined) {
          return parseInt(parts[1]);
        }
        return undefined;
      })
      .filter((i) => i !== undefined);
  },
  denormalizeApartments(apartments, floor) {
    return apartments.map((i) => {
      return (
        ("0" + floor.toString()).slice(-2) +
        "-" +
        ("0" + i.toString()).slice(-2)
      );
    });
  },
};
