import Vue from "vue";
import Vuex from "vuex";
import alerts from "./modules/alerts";
import UserRoles from "../utils/user-roles";

Vue.use(Vuex);

const debugMode = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  getters: {
    isNormalUser(state) {
      return state.userType === UserRoles.admin;
    },
    lang(state) {
      return state.lang;
    },
    allowedMenuItems(state) {
      return state.menu.filter(
        (mi) => mi.roles.includes(state.userType) && mi.visible
      );
    },
    userRole(state) {
      return state.userType;
    },
    userId(state) {
      return state.userId;
    },
  },
  state: {
    userId: null,
    userType: null,
    isUserAuthenticated: false,
    lang: null,
    languages: [],
    menu: [],
    baseTitle: "",
  },
  actions: {
    SET_MENU({ commit }, menu) {
      commit("SET_MENU", menu);
    },
  },
  mutations: {
    SET_USER_UNAUTHENTICATED(state) {
      state.isUserAuthenticated = false;
    },
    SET_USER_AUTHENTICATED(state) {
      state.isUserAuthenticated = true;
    },
    SET_USER_TYPE(state, val) {
      state.userType = val;
    },
    SET_USER_ID(state, val) {
      state.userId = val;
    },
    CLEAR_USER_TYPE(state) {
      state.userType = null;
    },
    CLEAR_USER_ID(state) {
      state.userId = null;
    },
    SET_MENU(state, menu) {
      state.menu = menu;
    },
    SET_LANG(state, lng) {
      state.lang = lng;
    },
    SET_LANG_LIST(state, languages) {
      state.languages = languages;
    },
    SET_BASE_TITLE(state, title) {
      state.baseTitle = title;
    },
  },

  modules: {
    alerts,
  },
  strict: debugMode,
});
