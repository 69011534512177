export default {
  auth: {
    login: "/api/v0/login",
    refresh: "/api/v0/refresh",
    logout: "/api/v0/logout",
  },
  users: {
    index: "/api/v0/users",
    item: "/api/v0/users/{id}",
  },
  devices: {
    index: "/api/v0/devices",
    item: "/api/v0/devices/{id}",
  },
  sessions: {
    index: "/api/v0/sessions/items",
    item: "/api/v0/sessions/items/{id}",
  },
};
