<script>
export default {
  name: "PopupAlerts",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<template>
  <div class="alerts-absolute-container">
    <transition-group name="slide-from-right">
      <v-alert
        v-for="alert in items"
        :key="alert.key"
        :value="true"
        :type="alert.variant"
        :style="{ 'min-width': $vuetify.breakpoint.mdAndUp ? '300px' : 'auto' }"
        border="left"
      >
        {{ alert.msg }}
        <template v-slot:close>
          <v-icon
            class="ml-2"
            @click.stop.prevent="$emit('close-alert', alert)"
          >
            clear
          </v-icon>
        </template>
      </v-alert>
    </transition-group>
  </div>
</template>

<style>
.alerts-absolute-container {
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  z-index: 90001;
}

.alerts-absolute-container .v-alert {
  min-width: 300px;
  margin: 10px 10px;
}
</style>
