import Events from "@/services/event-bus/events";
class PageService {
  constructor(store, eventBus) {
    this.store = store;

    eventBus.subscribe(Events.PAGE_LOADED, (title) => {
      this.setPageTabTitle(title);
    });
  }
  setPageTabTitle(title) {
    document.title = title + this.store.state.baseTitle;
  }
}

export default PageService;
