class SettingsService {
  get(name) {
    const rawValue = localStorage.getItem(name) || sessionStorage.getItem(name);
    try {
      return JSON.parse(rawValue);
    } catch (e) {
      return rawValue;
    }
  }
  set(name, rawValue, onlyForSession = false) {
    this.clear(name);
    const value = JSON.stringify(rawValue);
    if (onlyForSession) {
      sessionStorage.setItem(name, value);
      return;
    }
    localStorage.setItem(name, value);
  }
  clear(name) {
    sessionStorage.removeItem(name);
    localStorage.removeItem(name);
  }
}

export default SettingsService;
