class EventBus {
  constructor() {
    this.events = new Map();
  }
  subscribe(event, delegate) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(delegate);
  }
  unsubscribe(event, delegate) {
    if (this.events[event]) {
      this.events[event] = this.events[event].filter((d) => d !== delegate);
    }
  }
  emit(event, payload) {
    if (!this.events[event] || this.events[event].length === 0) {
      return;
    }
    let delegates = this.events[event].slice();
    for (const d of delegates) {
      d(payload);
    }
  }
}

export default EventBus;
