<script>
import Container from "@/services/container";
import ServiceTypes from "@/services/container/types";
import Events from "@/services/event-bus/events";

export default {
  name: "PageContainer",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const eb = Container.get(ServiceTypes.EVENT_BUS);
    eb.emit(Events.PAGE_LOADED, this.title);
  },
};
</script>

<template>
  <div>
    <slot></slot>
  </div>
</template>
