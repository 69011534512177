import endpoints from "./endpoints";
import HttpMethods from "@/utils/http-methods";
import Axios from "axios";
import Helpers from "../../utils/helpers";

const AUTHORIZATION_HTTP_HEADER = "Authorization";
const CONTENT_TYPE_HTTP_HEADER = "content-type";
const CONTENT_TYPE_HTTP_JSON = "application/json;charset=UTF-8";
// const CONTENT_TYPE_HTTP_MULTIPART_FORM = "multipart/form-data";

const SECOND = 1000;
// const MINUTE = SECOND * 60;

const EntityFieldsToApiModel = {
  createdAt: "created_at",
  updatedAt: "updated_at",
  ipAddress: "ip_address",
  expireAt: "expireAt",
  startAt: "start_at",
};

function mapUserApiModelToEntity({
  id,
  name,
  role,
  login,
  password,
  created_at,
}) {
  return {
    id,
    name,
    role,
    login,
    password,
    createdAt: created_at,
  };
}

function mapDeviceApiModelToEntity({
  serial_number,
  type,
  model,
  status,
  updated_at,
}) {
  return {
    serialNumber: serial_number,
    type,
    model,
    status,
    updatedAt: updated_at,
  };
}

function preparePaginationParams({
  pagination: { page, size },
  sorting: { field: sortingField, order: sortingOrder },
  filtering: { field: filteringField, value: filteringValue },
}) {
  const params = { page, size };
  if (sortingField) {
    params.sorting = EntityFieldsToApiModel[sortingField] ?? sortingField;
    // true == desc
    if (sortingOrder) {
      params.sorting = "-" + params.sorting;
    }
  }
  if (filteringField && filteringValue) {
    params.filterField =
      EntityFieldsToApiModel[filteringField] ?? filteringField;
    params.filterValue = filteringValue;
  }
  return params;
}

class BackendHttpApi {
  constructor(authToken) {
    const httpClientConfig = {
      baseUrl: "/",
    };

    this.httpClient = Axios.create(httpClientConfig);
    this.authToken = authToken;
  }

  setAuthToken(authToken) {
    this.authToken = authToken;
  }

  /**
   * Devices block
   * @returns {Promise<void>}
   */
  async getDevices(paginationOptions, sorting, filtering) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      endpoints.devices.index,
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data.map(mapDeviceApiModelToEntity),
      totalItems: total,
      page,
    };
  }

  async deleteDevice(id) {
    await this.request(
      HttpMethods.DELETE,
      Helpers.getParameterizedString(endpoints.devices.item, { id }),
      {},
      {}
    );
  }

  async getDevice(target) {
    const { data: device } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.devices.item, { id: target })
    );
    return mapDeviceApiModelToEntity(device);
  }

  async getDeviceApiInfo(target) {
    const {
      data: { status, api_info },
    } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.devices.item, { id: target }),
      {},
      null,
      {},
      10 * SECOND
    );
    return {
      status,
      apiInfo: {
        type: api_info?.type || "",
        model: api_info?.model || "",
        serialNumber: api_info?.serial_number || "",
        firmware: api_info?.firmware || "",
        frontendCommitHash: api_info?.commit || "",
      },
    };
  }

  async createSession(serialNumber, frontendCommitHash, model) {
    const { data: sessionId } = await this.request(
      HttpMethods.PUT,
      endpoints.sessions.index,
      {},
      {
        serial_number: serialNumber,
        frontend_commit_hash: frontendCommitHash,
        model,
      },
      [],
      60 * SECOND
    );
    return sessionId;
  }

  async updateSession(sessionId) {
    await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.sessions.item, { id: sessionId })
    );
  }

  async getSessions() {
    const { data: sessions } = await this.request(
      HttpMethods.GET,
      endpoints.sessions.index
    );

    return sessions.map(
      ({
        id,
        serial_number,
        model,
        frontend_commit_hash,
        username,
        created_at,
        updated_at,
      }) => {
        return {
          id,
          serialNumber: serial_number,
          model,
          frontendCommit: frontend_commit_hash,
          username,
          createdAt: created_at,
          updatedAt: updated_at,
        };
      }
    );
  }

  async deleteSession(sessionId) {
    await this.request(
      HttpMethods.DELETE,
      Helpers.getParameterizedString(endpoints.sessions.item, { id: sessionId })
    );
  }

  /**
   * Users block
   * @returns {Promise<void>}
   */
  async getUsers(paginationOptions, sorting, filtering) {
    const {
      data,
      pagination: { page, total },
    } = await this.request(
      HttpMethods.GET,
      endpoints.users.index,
      preparePaginationParams({
        pagination: paginationOptions,
        sorting,
        filtering,
      })
    );

    return {
      items: data.map(mapUserApiModelToEntity),
      totalItems: total,
      page,
    };
  }

  async createUser({ name, role, login, password }) {
    const { data } = await this.request(
      HttpMethods.PUT,
      endpoints.users.index,
      {},
      {
        name,
        role,
        login,
        password,
      }
    );
    return data;
  }

  async deleteUser(id) {
    await this.request(
      HttpMethods.DELETE,
      Helpers.getParameterizedString(endpoints.users.item, { id }),
      {},
      {}
    );
  }

  async updateUser({ id, name, role, login, password }) {
    const { data } = await this.request(
      HttpMethods.PATCH,
      Helpers.getParameterizedString(endpoints.users.item, { id }),
      {},
      {
        name,
        role,
        login,
        password,
      }
    );
    return data;
  }

  async getUser(id) {
    const {
      data: { name, role, login, password },
    } = await this.request(
      HttpMethods.GET,
      Helpers.getParameterizedString(endpoints.users.item, { id })
    );
    return {
      id,
      name,
      role,
      login,
      password,
    };
  }

  /**
   * Authentication block
   * @returns {Promise<{access: *, refresh: *, userType: *, userId: *}>}
   */
  async login(login, password) {
    const {
      data: { access, refresh, role, user_id },
    } = await this.request(
      HttpMethods.POST,
      endpoints.auth.login,
      {},
      {
        login,
        password,
      }
    );
    this.setAuthToken(access);
    return { access, refresh, userType: role, userId: user_id };
  }

  async logout() {
    await this.request(HttpMethods.POST, endpoints.auth.logout);
  }

  // async getLicenseFile(id) {
  //   const data = await this.requestRaw({
  //     method: HttpMethods.GET,
  //     responseType: "arraybuffer",
  //     url: Helpers.getParameterizedString(endpoints.licenses.item.file, { id }),
  //     timeout: 30 * SECOND,
  //   });
  //   return new Blob([data]);
  // }

  async request(
    method,
    url,
    params = {},
    data = null,
    headers = {},
    timeout = 3 * SECOND
  ) {
    let config = {
      method,
      params,
      url,
      timeout,
      headers: {
        [CONTENT_TYPE_HTTP_HEADER]: CONTENT_TYPE_HTTP_JSON,
      },
    };
    for (const [k, v] of Object.entries(headers)) {
      config.headers[k] = v;
    }
    config.data = data;
    return await this.requestRaw(config);
  }

  async requestRaw(config) {
    if (this.authToken) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers[AUTHORIZATION_HTTP_HEADER] = `Bearer ${this.authToken}`;
    }
    console.debug("requestRaw", config);
    return (await this.httpClient.request(config)).data;
  }
}

export default BackendHttpApi;
