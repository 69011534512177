class LocalizationService {
  constructor(storage, l18nPlugin, lang) {
    this.loadedLanguages = [];
    this.storage = storage;
    this.l18nPlugin = l18nPlugin;
    this.setLanguage(lang);
  }
  async setLanguage(lang) {
    if (this.storage.getters.lang === lang) {
      return;
    }

    if (await this.loadLanguageAsync(lang)) {
      this.loadedLanguages.push(lang);
      this.storage.commit("SET_LANG", lang);
      this.l18nPlugin.locale = lang;
    }
  }
  async loadLanguageAsync(lang) {
    // If the language was already loaded
    if (this.loadedLanguages.includes(lang)) {
      return true;
    }

    // If the language hasn't been loaded yet
    const messages = await import(
      /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}/index.js`
    );
    this.l18nPlugin.setLocaleMessage(lang, messages.default);

    return true;
  }
}

export default LocalizationService;
