import { v4 as uuidv4 } from "uuid";
const state = {
  items: [],
};

const getters = {};
const actions = {
  clearAlerts({ commit }) {
    commit("CLEAR_ALERTS");
  },
  showAlert(
    { commit, state },
    {
      variant = "success",
      dismissible = true,
      fade = true,
      timeout = 3,
      msg = "...",
    }
  ) {
    //workaround for hangs
    if (
      state.items.length > 0 &&
      state.items[state.items.length - 1].msg === msg
    ) {
      return;
    }
    const alertKey = uuidv4();
    commit("ADD_ALERT", {
      variant: variant,
      dismissible: dismissible,
      fade: fade,
      msg: msg,
      key: alertKey,
      timeout: timeout * 1000,
      active: true,
    });

    if (timeout > 0) {
      setTimeout(() => {
        commit("DISMISS_ALERT", alertKey);
      }, timeout * 1000);
    }
  },
  removeAlert({ commit }, { key }) {
    commit("DISMISS_ALERT", key);
  },
};

const mutations = {
  ADD_ALERT(state, msg) {
    state.items.push(msg);
  },
  DISMISS_ALERT(state, key) {
    state.items = state.items.filter((a) => a.key !== key);
  },
  CLEAR_ALERTS(state) {
    state.items = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
