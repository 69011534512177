import "./registerDependencies";
import BackendHttpApi from "./services/api";
import EventBus from "@/services/event-bus";
import Container from "@/services/container";
import ServiceTypes from "@/services/container/types";
import ApiPlugin from "./plugins/api";
import AlertsPlugin from "./plugins/alerts";
import ApiErrorHandlerPlugin from "./plugins/api-error-handler";

import { registerComponents } from "./registerComponents.js";
import messages from "./lang/index.js";
import VueI18n from "vue-i18n";

import Vue from "vue";
import App from "./App.vue";
import store from "./store/index.js";
import router from "./router/index.js";
import vuetify from "./plugins/vuetify";
import UserRoles from "@/utils/user-roles";

import LocalizationService from "@/services/localization";
import Languages from "./services/localization/languages";

import SettingsService from "@/services/settings";
import SettingsNames from "@/services/settings/names";
import PageService from "@/services/page";
import AuthService from "@/services/auth";
import LoggerService from "@/services/logger/console-logger";

const DEFAULT_LANG = "en";
const DEBUG_MODE = process.env.NODE_ENV !== "production";

export async function runApp() {
  const loggerService = new LoggerService(DEBUG_MODE);
  Container.bind(ServiceTypes.LOGGER, loggerService);

  // configure settings
  const settingsService = new SettingsService();
  const defaultLang = settingsService.get(SettingsNames.DEFAULT_LANG);
  const authToken = settingsService.get(SettingsNames.AUTH_TOKEN);
  const userType = settingsService.get(SettingsNames.USER_TYPE);
  const userId = settingsService.get(SettingsNames.USER_ID);
  loggerService.debug("got setting - authToken", authToken);
  loggerService.debug("got setting - defaultLang", defaultLang);
  Container.bind(ServiceTypes.SETTINGS, settingsService);

  const apiService = new BackendHttpApi(authToken);
  Vue.use(ApiPlugin(apiService));
  Container.bind(ServiceTypes.API, apiService);

  // configure page
  store.dispatch("SET_MENU", getAppSideMenu(apiService));

  // configure event bus
  const eventBus = new EventBus();
  Container.bind(ServiceTypes.EVENT_BUS, eventBus);

  // configure localization
  store.commit("SET_LANG_LIST", Languages);
  Vue.use(VueI18n);
  const i18nPlugin = new VueI18n({
    locale: defaultLang || DEFAULT_LANG,
    fallbackLocale: DEFAULT_LANG,
    messages, // set default locale messages
  });

  const localizationService = new LocalizationService(
    store,
    i18nPlugin,
    defaultLang || DEFAULT_LANG
  );
  Container.bind(ServiceTypes.LOCALIZATION, localizationService);

  // configure page service
  const pageService = new PageService(store, eventBus);
  Container.bind(ServiceTypes.PAGE, pageService);

  // configure auth service
  const authService = new AuthService(
    settingsService,
    apiService,
    store,
    loggerService,
    !!authToken,
    userType,
    userId
  );
  Container.bind(ServiceTypes.AUTH, authService);

  Vue.use(AlertsPlugin);
  Vue.use(
    new ApiErrorHandlerPlugin(
      apiService,
      authService,
      router,
      store,
      loggerService
    )
  );

  registerComponents();

  Vue.config.productionTip = false;

  new Vue({
    vuetify,
    router,
    store,
    i18n: i18nPlugin,
    render: (h) => h(App),
  }).$mount("#app");
}

function getAppSideMenu() {
  return [
    {
      icon: "computer",
      title: "devices",
      roles: [UserRoles.admin, UserRoles.manager],
      route: { name: "devices" },
      visible: true,
    },
    {
      icon: "lan",
      title: "sessions",
      roles: [UserRoles.admin],
      route: { name: "sessions" },
      visible: true,
    },
    {
      icon: "lock",
      title: "users",
      roles: [UserRoles.admin],
      route: { name: "users" },
      visible: true,
    },
  ];
}
