<script>
export default {
  name: "EntityTable",
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    itemKey: {
      type: String,
      default: "id",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => ({ page: 1, size: 25 }),
    },
    sorting: {
      type: Object,
      default: () => ({ field: null, order: null }),
    },
    disableEditing: {
      type: Boolean,
      default: false,
    },
    disableDeleting: {
      type: Boolean,
      default: false,
    },
    additionalActions: {
      type: Array,
      default: () => [],
    },
    additionalActionsByItem: {
      type: Function,
      default: () => [],
    },
    canEdit: {
      type: Function,
      default: () => true,
    },
    canDelete: {
      type: Function,
      default: () => true,
    },
    editOnClick: {
      type: Boolean,
      default: true,
    },
    actionsDisabled: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      confirmDialog: {
        loading: false,
        active: false,
        title: "",
        text: "",
        onApprove: () => {},
        onCancel: () => {},
      },
    };
  },
  computed: {
    actionsEnabled() {
      return !this.actionsDisabled;
    },
    footerProps() {
      return {
        "items-per-page-text": this.$t("interface.rows_per_page"),
        "items-per-page-options": [10, 25, 50, 100],
      };
    },
    tableHeaders() {
      const tableHeaders = this.headers.map(
        ({ field, value, translation, sorting }) => {
          return {
            text: this.$t("interface." + (translation ?? field)),
            value: field ?? value,
            sortable: sorting,
          };
        }
      );
      if (this.actionsEnabled) {
        tableHeaders.push({
          text: this.$t("interface.actions"),
          align: "center",
          value: "actions",
          sortable: false,
        });
      }

      return tableHeaders;
    },
  },
  methods: {
    showConfirmDialog(title, text, approve, cancel) {
      this.confirmDialog.title = title;
      this.confirmDialog.text = text;
      this.confirmDialog.onApprove = async () => {
        this.confirmDialog.loading = true;
        if (approve) {
          await approve();
        }
        this.confirmDialog.loading = false;
        this.confirmDialog.active = false;
      };
      this.confirmDialog.onCancel = () => {
        if (cancel) {
          cancel();
        }
        this.confirmDialog.active = false;
      };
      this.confirmDialog.active = true;
    },
    clickRow(item) {
      this.$emit("click-row", item);
      if (this.editOnClick) {
        this.editItem(item);
      }
    },
    editItem(item) {
      this.$emit("edit", item[this.itemKey]);
    },
    deleteItem(item) {
      this.showConfirmDialog(
        null,
        this.$t("interface.delete_selected"),
        () => {
          this.$emit("delete", item[this.itemKey]);
        },
        () => {}
      );
    },
    updateOptions(options) {
      const needUpdate =
        this.pagination.page !== options.page ||
        this.pagination.size !== options.itemsPerPage ||
        this.sorting.field !== options.sortBy[0] ||
        this.sorting.order !== options.sortDesc[0];

      if (needUpdate) {
        this.$emit("change:options", {
          pagination: {
            page: options.page,
            size: options.itemsPerPage,
            sorting: {
              field: options.sortBy[0],
              order: options.sortDesc[0],
            },
          },
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <v-dialog v-model="confirmDialog.active" max-width="400px">
      <v-card>
        <v-card-title v-show="!!confirmDialog.title">
          {{ confirmDialog.title }}
        </v-card-title>
        <v-card-text class="pt-4 px-4 pb-0"
          >{{ confirmDialog.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="confirmDialog.loading"
            color="error"
            text
            @click="confirmDialog.onCancel"
          >
            {{ $t("interface.cancel") }}
          </v-btn>
          <v-btn
            :loading="confirmDialog.loading"
            color="success"
            text
            @click="confirmDialog.onApprove"
          >
            {{ $t("interface.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      v-model="selected"
      :headers="tableHeaders"
      :items="items"
      :server-items-length="totalItems"
      :page="pagination.page"
      :items-per-page="pagination.size"
      :sort-by="sorting.field"
      :sort-desc="sorting.order"
      :loading="loading"
      :disable-pagination="loading"
      :disable-sort="loading"
      :footer-props="footerProps"
      :hide-default-footer="hideFooter"
      :must-sort="false"
      :no-data-text="
        loading ? $t('interface.please_wait') : $t('interface.no_data')
      "
      :item-key="itemKey"
      :show-select="false"
      @update:options="updateOptions"
    >
      <template #loading>
        <p class="text-center mb-0">{{ $t("interface.loading") }}</p>
      </template>
      <template #no-data>
        <p class="text-center mb-0">{{ $t("interface.no_data") }}</p>
      </template>
      <template #footer.page-text="props">
        {{
          $t("interface.records_of", {
            from: props.pageStart,
            to: props.pageStop,
            total: props.itemsLength,
          })
        }}
      </template>

      <template #item="props">
        <tr
          :active="props.selected"
          style="cursor: pointer"
          @click.stop="clickRow(props.item)"
        >
          <td v-if="false">
            <v-icon
              :class="{ 'accent--text': props.isSelected }"
              @click.stop="props.select(!props.isSelected)"
              >{{ props.isSelected ? "check_box" : "check_box_outline_blank" }}
            </v-icon>
          </td>

          <td v-for="h in headers" :key="h.field">
            <component
              :is="h.component"
              v-if="h.component"
              :value="props.item[h.value || h.field]"
              v-bind="h.componentProps(props.item)"
            >
              {{ props.item[h.value || h.field] }}
            </component>
            <template v-else>
              {{ props.item[h.value || h.field] }}
            </template>
          </td>

          <td v-if="actionsEnabled" class="px-0 text-no-wrap">
            <v-btn
              v-for="action in additionalActions"
              :key="action.id"
              icon
              class="mr-2 d-inline-block"
              small
              @click.stop="action.callback(props.item)"
            >
              <v-icon small :color="action.color">
                {{ action.icon }}
              </v-icon>
            </v-btn>
            <v-btn
              v-for="action in additionalActionsByItem(props.item)"
              :key="action.id"
              icon
              class="mr-2 d-inline-block"
              small
              @click.stop="action.callback(props.item)"
            >
              <v-icon small :color="action.color">
                {{ action.icon }}
              </v-icon>
            </v-btn>
            <v-btn
              v-if="!disableEditing && canEdit(props.item)"
              icon
              small
              class="mr-2 d-inline-block"
            >
              <v-icon small @click.stop="editItem(props.item)"> edit </v-icon>
            </v-btn>
            <v-btn
              v-if="!disableDeleting && canDelete(props.item)"
              icon
              small
              class="d-inline-block"
            >
              <v-icon small @click.stop="deleteItem(props.item)">delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
