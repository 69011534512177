import interfaceTranslates from "./interface.json";
import tooltipsTranslates from "./tooltips.json";
import validationTranslates from "./validation.json";
import errorsTranslates from "./errors.json";

export default {
  interface: interfaceTranslates,
  tooltips: tooltipsTranslates,
  validation: validationTranslates,
  errors: errorsTranslates,
};
