<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn dark :loading="loading" v-on="on">
        <v-icon color="white">language</v-icon>
        <span class="white--text">{{ $store.state.lang }}</span>
      </v-btn>
    </template>
    <v-list nav="nav">
      <v-list-item
        v-for="(item, index) in $store.state.languages"
        :key="index"
        @click="setLanguage(item)"
      >
        <v-list-item-title class="text-center">{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Container from "@/services/container";
import ServiceTypes from "@/services/container/types";
import SettingsNames from "@/services/settings/names";
import Events from "@/services/event-bus/events";

export default {
  name: "LangSwitcher",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async setLanguage(lang) {
      this.loading = true;
      const loggerService = Container.get(ServiceTypes.LOGGER);
      try {
        const localizationService = Container.get(ServiceTypes.LOCALIZATION);
        const settingsService = Container.get(ServiceTypes.SETTINGS);
        const eventBus = Container.get(ServiceTypes.EVENT_BUS);
        // set application language
        localizationService.setLanguage(lang);
        // set user preferred language
        settingsService.set(SettingsNames.DEFAULT_LANG, lang);
        // change browser tab root title
        this.$store.commit("SET_BASE_TITLE", this.$t("interface.root_title"));
        eventBus.emit(Events.LANG_CHANGED, lang);
      } catch (e) {
        loggerService.error(e);
      }
      this.loading = false;
    },
  },
};
</script>
