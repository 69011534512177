import Vue from "vue";
import PageContainer from "@/components/common/page-container";
import EntityTable from "@/components/common/entity-table";
import FilterField from "@/components/common/filter-field";
import PopupAlerts from "@/components/common/alerts";
import LangSwitcher from "@/components/common/lang-switcher";
import LoadingIndicator from "@/components/common/loading";

export function registerComponents() {
  Vue.component("PageContainer", PageContainer);
  Vue.component("EntityTable", EntityTable);
  Vue.component("FilterField", FilterField);
  Vue.component("PopupAlerts", PopupAlerts);
  Vue.component("LangSwitcher", LangSwitcher);
  Vue.component("LoadingIndicator", LoadingIndicator);
}
