<template>
  <div class="loader" />
</template>

<script>
export default {
  name: "LoadingIndicator",
  components: {},
  props: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
}
</style>
