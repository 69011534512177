import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#212121",
        secondary: "#f50057",
        accent: "#f50057",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    iconfont: "md", // || "mdiSvg" || "md" || "fa" || "fa4" || "mdi",
  },
});
