export default function (deviceService, authService, router, store, logger) {
  return {
    install(Vue) {
      Vue.prototype.$handleApiError = async function (errorObject) {
        if (!errorObject) {
          return;
        }
        logger.error(errorObject);
        let errorText = errorObject;
        let errorData = errorObject;

        // if error has device api response and it is "auth required"
        // logout current user
        if (errorObject.response) {
          if (errorObject.response.status === 401) {
            if (store.state.isUserAuthenticated) {
              try {
                await authService.logout();
                router.push({ name: "login" });
              } catch (e) {
                return e;
              }
            }
            return;
          }
          if (errorObject.response.data) {
            errorData = errorObject.response.data;
          }
        }

        // if error data is blob type, read blob as text
        if (errorData instanceof Blob) {
          logger.debug("errorData instanceof Blob");
          let rprm = new Promise((res) => {
            let fr = new FileReader();
            fr.onload = () => {
              res(JSON.parse(fr.result));
            };
            fr.readAsText(errorData);
          });
          errorData = await rprm;
        }

        // if error has error attribute, use it as error text
        if (errorData.error) {
          logger.debug("error has 'error' attribute");

          const errorMessage = errorData.error.message?.toLowerCase();
          const errorCode = errorData.error.code?.toLowerCase();

          const messageTranslation = this.$t(`errors.${errorMessage}`);
          const codeTranslation = this.$t(`errors.${errorCode}`);

          if (messageTranslation !== `errors.${errorMessage}`) {
            errorText = messageTranslation;
          } else if (codeTranslation !== `errors.${errorCode}`) {
            errorText = codeTranslation;
          } else if (errorMessage) {
            errorText = errorMessage;
          } else if (errorCode) {
            errorText = errorCode;
          }
        } else {
          errorText = this.$t("errors.e_undefined");
        }

        // show alert with error
        store.dispatch("showAlert", {
          msg: errorText,
          variant: "error",
          timeout: 20,
        });
      };
    },
  };
}
