export default {
  install(Vue) {
    Vue.prototype.$showAlert = function (msg, variant, timeout = 5) {
      this.$store.dispatch("showAlert", {
        msg,
        variant,
        timeout,
      });
    };
    Vue.prototype.$showErrorObject = function (e) {
      this.$showAlert(e.name + ": " + e.message, "error");
    };
    Vue.prototype.$showError = function (msg) {
      this.$showAlert(msg, "error");
    };
    Vue.prototype.$showWarning = function (msg) {
      this.$showAlert(msg, "warning");
    };
    Vue.prototype.$showSuccess = function (msg) {
      this.$showAlert(msg, "success");
    };
    Vue.prototype.$showInfo = function (msg) {
      this.$showAlert(msg, "info");
    };
    Vue.prototype.$showDone = function () {
      this.$showSuccess(this.$t("interface.done"));
    };
  },
};
