class ConsoleLogger {
  constructor(isDebugModeEnabled) {
    this.isDebugModeEnabled = isDebugModeEnabled;
  }
  info(message, data = {}) {
    console.info(Date(), message, data);
  }
  debug(message, data = {}) {
    if (this.isDebugModeEnabled) {
      console.debug(Date(), message, data);
    }
  }
  log(message, data = {}) {
    console.log(Date(), message, data);
  }
  error(message, data = {}) {
    console.error(Date(), message, data);
  }
}

export default ConsoleLogger;
